@font-face {
  font-family: "sansRegular";
  src: url(./fonts/notoSansKrFont/NotoSansKR-Regular.ttf);
}
@font-face {
  font-family: "sansMedium";
  src: url(./fonts/notoSansKrFont/NotoSansKR-Medium.ttf);
}
@font-face {
  font-family: "sansBold";
  src: url(./fonts/notoSansKrFont/NotoSansKR-Bold.ttf);
}

:root {
  --font-sansRegular: "sansRegular";
  --font-sansMedium: "sansMedium";
  --font-sansBold: "sansBold";
}

body {
  
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #8c8c8c;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
